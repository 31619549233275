import React, { useEffect, useState } from 'react'
import MainLayout from '../layouts/mainLayout'
import OtherSectionContent from '../components/otherSectionContent'
import { useSelector } from 'react-redux'
import otherSectionTypes from '../constants/otherSectionTypes.constants'

const Home = () => {
  const sectionTopics = useSelector(state => state.section.sectionTopics)
  const [homepageId, setHomePageId] = useState(0)

  useEffect(() => {
    const otherSections =
      sectionTopics && sectionTopics.find(x => x.isOtherSection)?.otherSections
    const homeSection =
      otherSections &&
      otherSections.find(x => x.otherSectionType === otherSectionTypes.HOMEPAGE)
    setHomePageId(homeSection?.id)
  }, [sectionTopics])

  return (
    <>
      <MainLayout>
        <OtherSectionContent otherSectionId={homepageId} />
      </MainLayout>
    </>
  )
}

export default Home
