import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Grid, Button, Paper, makeStyles, Link } from '@material-ui/core'
import LoadingSpinner from '../components/loadingSpinner'
import DocumentLink from '../components/documentLink'
import { replaceCimLinks } from '../services/cim.service'
import topicService from '../services/topic.service'
import documentLocationConstants from '../constants/documentLocation.constants'

const diagramHelper = window.kendo.dataviz.diagram

const useStyles = makeStyles(theme => ({
  workflowContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

function TopicDynamicWorkflow({
  topic,
  segmentId,
  segmentName,
  sectionAndTopicDetails,
}) {
  const classes = useStyles()
  const refContainer = useRef()
  const refHiddenSelectedComponentId = useRef()
  const segmentHtmlRef = useRef()

  const [hasError, setHasError] = useState(false)
  const [segment, setSegment] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [segmentFromRouter, setSegmentFromRouter] = useState()

  const createSegmentTrackingQueryString = () => {
    const queryObject = {
      sectionId: sectionAndTopicDetails.sectionId,
      sectionName: sectionAndTopicDetails.sectionName,
      topicId: sectionAndTopicDetails.topicId,
      topicName: sectionAndTopicDetails.topicName,
      segmentId: segment.id,
      segmentName: segment.name,
    }
    return Object.keys(queryObject)
      .map(key => key + '=' + queryObject[key])
      .join('&')
  }

  const buildShape = component => {
    return {
      id: component.id.toString(),
      editable: false,
      selectable: false,
      visual: () => {
        var group = new diagramHelper.Group({ autoSize: true })
        var img = new diagramHelper.Image({
          source:
            `${window.config.CDN}/stc-diagram-images/` +
            component.topicDiagramImage,
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          content: { color: '#000' },
        })
        group.append(img)
        var layout = new diagramHelper.Layout(
          new diagramHelper.Rect(0, 0, 200, 200),
          {
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            orientation: 'vertical',
          }
        )
        if (component.shapeLabel1)
          layout.append(
            new diagramHelper.TextBlock({
              text: component.shapeLabel1,
              fontSize: 12,
              fill: '000000',
            })
          )
        if (component.shapeLabel2)
          layout.append(
            new diagramHelper.TextBlock({
              text: component.shapeLabel2,
              fontSize: 12,
              fill: '000000',
            })
          )
        if (component.shapeLabel3)
          layout.append(
            new diagramHelper.TextBlock({
              text: component.shapeLabel3,
              fontSize: 12,
              fill: '000000',
            })
          )
        if (component.shapeLabel4)
          layout.append(
            new diagramHelper.TextBlock({
              text: component.shapeLabel4,
              fontSize: 12,
              fill: '000000',
            })
          )
        layout.reflow()
        group.append(layout)
        return group
      },
    }
  }

  const buildConnection = (componentFromId, connectionTo) => {
    return {
      from: componentFromId.toString(),
      to: connectionTo.topicAccessLevelVersionSegmentToId.toString(),
      editable: false,
      selectable: false,
      endCap: {
        type: 'ArrowEnd',
        fill: {
          color: '#222222',
        },
      },
      content: {
        text: connectionTo.label,
      },
    }
  }

  const bringIntoView = (drawnDiagram, selectedShape) => {
    drawnDiagram.bringIntoView(
      new diagramHelper.Rect(
        selectedShape.options.x + 100,
        selectedShape.options.y + 200,
        1,
        1
      ),
      { animate: true }
    )
  }

  const drawDiagram = useCallback(() => {
    let $ = window.jQuery

    var shapes = []
    var connections = []

    topic.components.forEach(component => {
      const newShape = buildShape(component)
      shapes.push(newShape)
      component.topicAccessLevelVersionSegmentConnectionFrom.forEach(
        connection => {
          const newConnection = buildConnection(component.id, connection)
          connections.push(newConnection)
        }
      )
    })

    $(refContainer.current).kendoDiagram({
      shapes: shapes,
      connections: connections,
      layout: {
        type: 'layered',
        subtype: 'down',
      },
      zoom: 1,
      pannable: {
        key: 'none', //CAN DRAG DIAGRAM WITHOUT HOLDING CTRL
      },
      click: e => {
        if (e.item instanceof diagramHelper.Shape) {
          const shapeComponent = topic.components.find(
            component => component.id === parseInt(e.item.id)
          )
          setSelectedComponent(shapeComponent)
        }
      },
      dataBound: () => {
        setTimeout(() => {
          let selectedComponent = topic.components.find(x => x.id === segmentId)

          // if it doesnt find in components list by segmentId, try again based on SegmentName
          // that is being passed in the url
          if (!selectedComponent) {
            selectedComponent = topic.components.find(
              component => component.name === segmentName
            )
          }

          if (selectedComponent) setSelectedComponent(selectedComponent)
          else setSelectedComponent(topic.components[0])
        }, 0)
      },
    })

    //CAN'T TURN OFF UNDO AND REDO STACK
    //ON CTRL-Z, CALL THE RE-DO FUNCTION TO HIDE THIS BUG
    $(refContainer.current).keydown(e => {
      if (e.ctrlKey && e.keyCode === 90) {
        const drawnDiagram = $(refContainer.current).getKendoDiagram()
        if (drawnDiagram) {
          drawnDiagram.redo()
        }
      }
    })

    window.addEventListener('resize', () => {
      const drawnDiagram = $(refContainer.current).getKendoDiagram()
      if (drawnDiagram) {
        const selectedShape = drawnDiagram.shapes.find(
          shape => shape.id === refHiddenSelectedComponentId.current.value
        )
        if (selectedShape) bringIntoView(drawnDiagram, selectedShape)
      }
    })
  }, [topic, segmentId])

  const forwardButtons = () => {
    const toIds = selectedComponent.topicAccessLevelVersionSegmentConnectionFrom.map(
      connection => connection.topicAccessLevelVersionSegmentToId
    )
    const forwardComponents = topic.components.filter(component =>
      toIds.includes(component.id)
    )

    if (!forwardComponents.length) return null

    return (
      <>
        <h4>Next Steps</h4>
        {forwardComponents.map(component => (
          <Button
            key={component.id}
            component={Link}
            color='primary'
            style={{ display: 'block', marginBottom: 4 }}
            onClick={() => setSelectedComponent(component)}>
            {component.shapeLabel1} {component.shapeLabel2}{' '}
            {component.shapeLabel3} {component.shapeLabel4}
          </Button>
        ))}
      </>
    )
  }

  const backwardButtons = () => {
    const fromIds = selectedComponent.topicAccessLevelVersionSegmentConnectionTo.map(
      connection => connection.topicAccessLevelVersionSegmentFromId
    )
    const backwardComponents = topic.components.filter(component =>
      fromIds.includes(component.id)
    )

    if (!backwardComponents.length) return null

    return (
      <>
        <h4>Previous Steps</h4>
        {backwardComponents.map(component => (
          <Button
            key={component.id}
            component={Link}
            color='secondary'
            style={{ display: 'block', marginBottom: 4 }}
            onClick={() => setSelectedComponent(component)}>
            {component.shapeLabel1} {component.shapeLabel2}{' '}
            {component.shapeLabel3} {component.shapeLabel4}
          </Button>
        ))}
      </>
    )
  }

  useEffect(() => {
    if (segmentId) {
      setSegmentFromRouter(topic.components.find(seg => seg.Id === segmentId))
    }
  }, [segmentId])

  useEffect(() => {
    if (segmentFromRouter) {
      setSelectedComponent(segmentFromRouter)
    }
  }, [segmentFromRouter])

  //THESE USE EFFECTS FOLLOW A FLOW AND ARE MEANT TO BE RAN IN THE DESIGNATED ORDER
  //BE CAREFUL ABOUT ADDING DEPENDENCIES THAT MAY CHANGE THE ORDER

  //EXAMPLE:
  //SECTION ID SECTION ONLY EXISTS ON THE TOPIC OBJECT BUT IS NEEDED IN THE THIRD EVENT
  //SECTION ID HAS BEEN ADDED TO THE WORKFLOW OBJECT IN THE FIRST EVENT
  //SO THAT TOPIC IS NOT A DEPENDENCY ON THE THIRD EVENT AND IT CAN ONLY BE FIRED AFTER THE FIRST EVENT

  //FIRST EVENT: DRAWS DIAGRAM WHEN TOPIC PROP IS INITIALISED
  useEffect(() => {
    try {
      if (topic) {
        drawDiagram()
      }
    } catch (e) {
      console.log(e)
      setHasError(true)
    }
  }, [topic, drawDiagram])

  //SECOND EVENT: HAPPENS WHEN TOPIC PROP IS INITIALISED OR A DIFFERENT SEGMENT IS SELECTED IN THE UI
  useEffect(() => {
    if (selectedComponent) {
      let $ = window.jQuery
      refHiddenSelectedComponentId.current.value = selectedComponent.id
      const drawnDiagram = $(refContainer.current).getKendoDiagram()
      if (drawnDiagram) {
        const selectedShape = drawnDiagram.shapes.find(
          shape => parseInt(shape.id) === selectedComponent.id
        )
        if (selectedShape) {
          bringIntoView(drawnDiagram, selectedShape)
          setSegment(selectedComponent)
        }
      }
    }
  }, [selectedComponent, topic])

  //THIRD EVENT: HAPPENS WHEN THE SEGMENT CHANGES
  useEffect(() => {
    if (segment) {
      replaceCimLinks(segmentHtmlRef.current)

      topicService
        .sectionTopicSegmentTracking(createSegmentTrackingQueryString())
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [segment])

  return (
    <>
      {!hasError ? (
        <>
          <input ref={refHiddenSelectedComponentId} type='hidden' />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!topic && <LoadingSpinner text={`Loading ${topic.name}`} />}
            </Grid>
            {topic && (
              <>
                <Grid item md={6} sm={12} xs={12}>
                  <div
                    className={classes.workflowContainer}
                    ref={refContainer}></div>
                </Grid>
                {segment && (
                  <Grid item md={6} sm={12} xs={12}>
                    <Paper style={{ padding: 24, marginBottom: 12 }}>
                      <h3 style={{ marginTop: 0 }}>{segment.name}</h3>
                      <div
                        ref={segmentHtmlRef}
                        dangerouslySetInnerHTML={{
                          __html: segment.contentHtml,
                        }}></div>
                    </Paper>

                    {segment.documentIds && segment.documentIds.length > 0 && (
                      <Paper style={{ padding: 24, marginBottom: 12 }}>
                        <>
                          <h3 style={{ marginTop: 0 }}>Documents</h3>
                          {topic.documents
                            .filter(document =>
                              segment.documentIds.includes(document.id)
                            )
                            .sort((a, b) =>
                              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                            )
                            .map(document => (
                              <DocumentLink
                                key={document.id}
                                documentId={document.id}
                                fileName={document.filename}
                                documentName={document.name}
                                showIcon={true}
                                sectionAndTopicDetails={sectionAndTopicDetails}
                                documentLocation={
                                  documentLocationConstants.SEGMENT
                                }
                              />
                            ))}
                        </>
                      </Paper>
                    )}

                    <Paper style={{ padding: 24, marginBottom: 12 }}>
                      <h3 style={{ marginTop: 0 }}>Navigation</h3>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {forwardButtons()}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {backwardButtons()}
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </>
      ) : (
        <Paper style={{ padding: 24 }}>
          <p>Error, Could not load topic...</p>
        </Paper>
      )}
    </>
  )
}

export default TopicDynamicWorkflow
