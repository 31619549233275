import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button, Input, InputAdornment } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CloseIcon from '@material-ui/icons/Close'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SearchIcon from '@material-ui/icons/Search'
import { signOutRedirect } from '../services/auth.service'
import availabilityConstants from '../constants/availability.constants'
import ProtectedSection from '../components/protectedSection'
import roleConstants from '../constants/roles.constants'
import { showAccessLevelPrompt } from '../actions/appconfig.actions'
import history from '../history'
import { serviceApplicationConstants } from '../constants/serviceApplications.constants'

import {
  Collapse,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  ListItem,
  ListItemText,
  Badge,
  Divider,
  List,
  Drawer,
  ListItemIcon,
} from '@material-ui/core'
import { useParams } from 'react-router'

import { setUserAvailabilityAsync } from '../actions/user.actions'
import otherSectionTypes from '../constants/otherSectionTypes.constants'

const drawerWidth = '75vw'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontSize: '0.6rem',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 16,
    backgroundColor: theme.palette.navigation.main,
    color: theme.palette.getContrastText(theme.palette.navigation.main),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badgeContainer: {
    padding: 16,
  },
  badge: {
    marginRight: 16,
  },
  avatar: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#42424e',
    color: '#42424e',
  },
  avatarHighlighted: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e74c3c',
    color: '#e74c3c',
  },
  employeeDataItem: {
    marginBottom: 5,
    overflow: 'hidden',
  },
  backToMainMenu: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  availabilityLens: {
    fontSize: '0.8em',
    marginRight: '8px',
  },
  availabilityBadgeAvailable: {
    backgroundColor: 'green',
  },
  availabilityBadgeBusy: {
    backgroundColor: 'yellow',
  },
  availabilityBadgeUnavailable: {
    backgroundColor: 'red',
  },
  availabilityOptionAvailable: {
    color: 'green',
    '&$availabilityOptionAvailableChecked': { color: 'green' },
  },
  availabilityOptionBusy: {
    color: 'yellow',
    '&$availabilityOptionBusyChecked': { color: 'yellow' },
  },
  availabilityOptionUnavailable: {
    color: 'red',
    '&$availabilityOptionUnavailableChecked': { color: 'red' },
  },
  // DO NOT DELETE these blank styles. They are required to for styling the availaility radio buttons
  // https://material-ui.com/customization/components/#overriding-styles-with-classes
  availabilityOptionAvailableChecked: {},
  availabilityOptionBusyChecked: {},
  availabilityOptionUnavailableChecked: {},
  menuItem: {
    paddingRight: 10,
  },
}))

const SideDrawer = ({ open, setOpen }) => {
  const classes = useStyles()
  const params = useParams()

  const [displayMode, setDisplayMode] = useState('default')
  const [openSectionId, setOpenSectionId] = useState(null)

  const { sectionTopics } = useSelector(state => state.section)
  const { serviceLabelData } = useSelector(state => state.serviceLabel)
  const { isSearching } = useSelector(state => state.search)
  const serviceApplications = useSelector(state => state.branding.applications)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const dashboardUrl = serviceApplications.find(
    x => x.id === serviceApplicationConstants.DASHBOARD
  )?.url

  const handleSectionClick = sectionId => {
    if (openSectionId === null) {
      setOpenSectionId(sectionId)
      return
    }
    if (openSectionId !== sectionId) {
      setOpenSectionId(sectionId)
      return
    }
    setOpenSectionId(null) //MUST BE CLOSING OPEN SECTION
  }

  const handleAvailabilityChange = event => {
    dispatch(setUserAvailabilityAsync(event.target.value))
  }

  const handleCreateCase = () => {
    let queryParams = ''

    if (params.sectionId && params.topicId) {
      queryParams += `?sectionId=${params.sectionId}&topicId=${params.topicId}`
    }

    const returnUrl = window.location.href
    queryParams += queryParams ? '&' : '?'
    queryParams += `returnUrl=${returnUrl}`

    window.location.href = `${dashboardUrl}/cases/create${queryParams}`
  }

  const handleLogout = () => {
    dispatch(setUserAvailabilityAsync(availabilityConstants.OFFLINE))
    signOutRedirect()
  }

  const handleChangePolicy = () => {
    dispatch(showAccessLevelPrompt())
  }

  const [searchTerm, setSearchTerm] = useState('')
  const handleSearch = () => {
    const encodedSearchTerm = encodeURI(searchTerm)
    history.push(`/Search/${encodedSearchTerm}`)
  }
  const handleSearchSubmit = e => {
    e.preventDefault()
    setOpen(false)
    handleSearch()
  }

  return (
    <>
      {sectionTopics && (
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader} onClick={() => setOpen(false)}>
            <span>CLOSE</span>
            <CloseIcon />
          </div>

          {displayMode === 'default' && (
            <>
              <div className={classes.badgeContainer}>
                <Badge
                  classes={{
                    badge: classes[`availabilityBadge${user.availability}`],
                  }}
                  variant='dot'
                  overlap='circle'
                  onClick={() => setDisplayMode('availability')}>
                  <AccountCircleIcon />
                </Badge>
                <Badge
                  onClick={() => setDisplayMode('search')}
                  className={classes.badge}
                  overlap='circle'>
                  <SearchIcon />
                </Badge>
              </div>

              <List component='nav'>
                <ListItem button component={Link} to='/'>
                  <ListItemText primary={'Home'} />
                </ListItem>
                <Divider />
                {sectionTopics.map(section => (
                  <>
                    <ListItem
                      button
                      onClick={() => handleSectionClick(section.id)}>
                      <ListItemText primary={section.name} />
                      <ListItemIcon>
                        {openSectionId === section.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      in={openSectionId === section.id}
                      timeout='auto'
                      unmountOnExit>
                      <List component='div' disablePadding>
                        {!section.isOtherSection
                          ? section.topics.map(topic => (
                              <ListItem
                                className={classes.nested}
                                button
                                component={Link}
                                onClick={() => setOpen(false)}
                                to={`/topic/${topic.id}`}>
                                <ListItemText primary={topic.name} />
                              </ListItem>
                            ))
                          : section.otherSections
                              .filter(
                                otherSection =>
                                  otherSection.otherSectionType !==
                                  otherSectionTypes.HOMEPAGE
                              )
                              .map(otherSection => (
                                <ListItem
                                  className={classes.nested}
                                  button
                                  component={Link}
                                  onClick={() => setOpen(false)}
                                  to={`/other/${otherSection.id}`}>
                                  <ListItemText primary={otherSection.name} />
                                </ListItem>
                              ))}
                      </List>
                    </Collapse>
                  </>
                ))}
                <Divider />
                <ProtectedSection
                  rolesRequired={[roleConstants.HR, roleConstants.BUSINESS]}>
                  <ListItem button component='a' href={dashboardUrl}>
                    <ListItemText primary='Case Manager' />
                  </ListItem>
                </ProtectedSection>
                <ListItem
                  button
                  component='a'
                  href={
                    serviceApplications.find(
                      x => x.id === serviceApplicationConstants.KNOWLEDGE_PORTAL
                    )?.url
                  }>
                  <ListItemText primary='Knowledge Portal' />
                </ListItem>
                <ProtectedSection rolesRequired={[roleConstants.MI]}>
                  <ListItem
                    button
                    component='a'
                    href={window.config.MI_PORTAL_URL}>
                    <ListItemText primary='MI Portal' />
                  </ListItem>
                </ProtectedSection>
                <ProtectedSection rolesRequired={[roleConstants.ADMIN]}>
                  <ListItem button component='a' to='/'>
                    <ListItemText primary='Admin' />
                  </ListItem>
                </ProtectedSection>
                <Divider />
                <ListItem button onClick={handleCreateCase}>
                  <ListItemText primary={'Create Case'} />
                </ListItem>
              </List>
            </>
          )}
          {displayMode === 'search' && (
            <>
              <div style={{ margin: 16 }}>
                <div
                  className={classes.backToMainMenu}
                  onClick={() => setDisplayMode('default')}>
                  <ChevronLeftIcon /> <p>BACK TO MAIN MENU</p>
                </div>
                <Divider />
                <form onSubmit={e => handleSearchSubmit(e)}>
                  <Input
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      width: '100%',
                      padding: '10px',
                    }}
                    type='text'
                    placeholder='Search Knowledge Portal'
                    disabled={isSearching}
                    onChange={e => setSearchTerm(e.target.value)}
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                  <Button variant='contained' color='primary' type='submit'>
                    Search
                  </Button>
                </form>
              </div>
            </>
          )}
          {displayMode === 'availability' && (
            <>
              <div style={{ margin: 16 }}>
                <div
                  className={classes.backToMainMenu}
                  onClick={() => setDisplayMode('default')}>
                  <ChevronLeftIcon /> <p>BACK TO MAIN MENU</p>
                </div>
                <Divider />
                <ProtectedSection rolesRequired={[roleConstants.HR]}>
                  <FormLabel component='legend' style={{ marginTop: 5 }}>
                    Set Availability
                  </FormLabel>
                  <FormControl component='fieldset' style={{ width: '100%' }}>
                    <RadioGroup
                      value={user.availability}
                      onChange={handleAvailabilityChange}>
                      <FormControlLabel
                        value='Available'
                        control={
                          <Radio
                            classes={{
                              root: classes.availabilityOptionAvailable,
                              checked:
                                classes.availabilityOptionAvailableChecked,
                            }}
                          />
                        }
                        label='Available'
                      />
                      <Divider />
                      <FormControlLabel
                        value='Busy'
                        control={
                          <Radio
                            classes={{
                              root: classes.availabilityOptionBusy,
                              checked: classes.availabilityOptionBusyChecked,
                            }}
                          />
                        }
                        label='Busy'
                      />
                      <Divider />
                      <FormControlLabel
                        value='Unavailable'
                        control={
                          <Radio
                            classes={{
                              root: classes.availabilityOptionUnavailable,
                              checked:
                                classes.availabilityOptionUnavailableChecked,
                            }}
                          />
                        }
                        label='Unavailable'
                      />
                      <Divider />
                    </RadioGroup>
                  </FormControl>
                  <Divider />
                </ProtectedSection>
                <Button onClick={handleChangePolicy}>
                  <ImportExportIcon className={classes.menuItem} />
                  Change {serviceLabelData.accessLevelsHeading}
                </Button>
                <Button onClick={handleLogout}>
                  <ExitToAppIcon className={classes.menuItem} />
                  Log Out
                </Button>
              </div>
            </>
          )}
        </Drawer>
      )}
    </>
  )
}

export default SideDrawer
